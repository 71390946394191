import { ChartView } from '../../../../enums/chart-view.enum';
import styles from './LpSwipeQuestionToggleForQuiz.module.css';
import clockSvg from '../../../../images/clock.svg';
import whiteClockSvg from '../../../../images/white-clock.svg';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
  onChangeView: (currentView: ChartView) => void;
  currentView: ChartView;
}

export const LpSwipeQuestionToggleForQuiz = ({
  onChangeView,
  currentView,
}: Props) => {
  return (
    <div className={styles.toggle}>
      <div
        className={
          currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE
            ? styles.activeViewForResponse
            : styles.activeViewForCard
        }
      >
        {currentView === ChartView.RIGHT_SWIPES_RESPONSE_RATE ? (
          <FontAwesomeIcon
            icon={faCheck}
            height={16}
            width={16}
            color="#fff"
            title="highest correct percentage active"
          />
        ) : (
          <img src={whiteClockSvg} alt="chronological view active" />
        )}
      </div>

      <FontAwesomeIcon
        icon={faCheck}
        height={16}
        width={16}
        className={styles.icon}
        onClick={() => onChangeView(ChartView.RIGHT_SWIPES_RESPONSE_RATE)}
        title="disable highest correct percentage view"
      />
      <img
        height={16}
        width={16}
        src={clockSvg}
        onClick={() => onChangeView(ChartView.CHRONOLOGICAL)}
        alt="disable chronological view"
        className={styles.icon}
      />
    </div>
  );
};
